@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply h-dvh;
  }
  body {
    @apply h-dvh;
  }
  div#__next {
    @apply h-dvh;
  }
  main {
    @apply h-dvh;
  }

  section {
    @apply h-dvh;
  }
  .scroll-hidden::-webkit-scrollbar {
    height: 0px;
    background: transparent;
    /* make scrollbar transparent */
}